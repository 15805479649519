<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-model-table model-name="Permissions" model-title="Permissions"
          :model-api="['models','User','Permissions']" model-key="permissionId" :headers="headers" 
          :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
          searchable>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field :rules="[rules.require()]" v-model="data.permissionId" label="Permission ID" :disabled="!isCreating"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="data.description" label="Description"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Permission ID',
        value: 'permissionId',
        class: 'body-2'
      },
      {
        text: 'Description',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
}
</script>

<style lang="css">
</style>
